@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    /* @apply scroll-smooth; */
    /* scroll-padding-top: 250px; */
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
